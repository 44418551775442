import * as React from "react"
import { Link } from "gatsby"
import Phone from "../svg/phone"

const links = {
  "/oferta": "Oferta",
  "/cennik": "Cennik",
  "/dla-firm": "Dla firm",
}

const Header = () => (
  <header className={"header"}>
    <div className="header__logo">
      <Link to="/">
        <img src="/images/logo.png" alt="logo" />
      </Link>
    </div>
    <div className="header__navigation">
      <ul className="header__menu">
        {Object.keys(links).map(link => (
          <li key={link}>
            <Link className={"header__menu-item"} to={link}>
              {links[link]}
            </Link>
          </li>
        ))}
        <li>
          <a
            href="http://mobitaxi.szczecin.pl/"
            target={"_blank"}
            rel={"nofollow noopener noreferrer"}
            className={"header__menu-item"}
          >
            Aplikacja
          </a>
        </li>
        <li>
          <Link className={"header__menu-item"} to={"/o-nas"}>
            O nas
          </Link>
        </li>
      </ul>
      <Link className={"button button--secondary"} to={"/dolacz"}>
        Dołącz i zarabiaj
      </Link>
      <a
        className={"button button--primary"}
        href={"tel:914-615-615"}
        data-g={"hide@md"}
      >
        <Phone />
        Zamów taxi
      </a>
      <button className={"button button--primary button--copy"}>
        <Phone />
        914 615 615
      </button>
    </div>
  </header>
)

export default Header
