import * as React from "react"
import { Link } from "gatsby"
import Phone from "../svg/phone"
import Confetti from "../svg/footer/confetti"
import { StaticImage } from "gatsby-plugin-image"

const general = {
  "/o-nas": "O nas",
  "/oferta": "Oferta",
}

const services = {
  "/oferta": "Obsługa imprez",
  "/dla-firm": "Oferta dla biznesu",
  "/dolacz": "Dołącz do nas",
}

const other = {
  "/polityka-prywatnosci": "Polityka prywatności",
}

const Footer = () => (
  <>
    <div className="pre-footer">
      <div data-g="hide show@md">
        <StaticImage
          src="../images/pre-footer/index.png"
          alt="taxi, mateusz, kierowca"
        />
      </div>
      <div data-g="hide@md">
        <StaticImage
          src="../images/pre-footer/first.png"
          alt="taxi, mateusz, kierowca"
        />
        <StaticImage
          src="../images/pre-footer/second.png"
          alt="taxi, mateusz, kierowca"
        />
        <StaticImage
          src="../images/pre-footer/third.png"
          alt="taxi, mateusz, kierowca"
        />
      </div>
    </div>

    <footer className={"footer"}>
      <div className={"footer__top"}>
        <div data-g={"container"}>
          <div data-g="grid">
            <div data-g="4 3@md">
              <img
                src="/images/logo-white.png"
                alt="białe logo"
                className={"footer__logo"}
              />
              <div className="footer__logo-text">
                Taxi Prawobrzeże sp. z o. o. <br />
                ul. Struga 44 <br />
                70-787 Szczecin
              </div>
            </div>
            <div data-g="4 3@md">
              <div className="footer__heading">Ogólne</div>
              <ul>
                {Object.keys(general).map(key => (
                  <li key={key}>
                    <Link to={key}>{general[key]}</Link>
                  </li>
                ))}
                <li>
                  <a
                    href="http://mobitaxi.szczecin.pl/"
                    target={"_blank"}
                    rel={"nofollow noopener noreferrer"}
                  >
                    Aplikacja mobilna
                  </a>
                </li>
              </ul>
            </div>
            <div data-g="4 3@md">
              <div className="footer__heading">Usługi</div>
              <ul>
                {Object.keys(services).map(key => (
                  <li key={key}>
                    <Link to={key}>{services[key]}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div data-g="4 3@md">
              <div className="footer__heading">Inne</div>
              <ul>
                {Object.keys(other).map(key => (
                  <li key={key}>
                    <Link to={key}>{other[key]}</Link>
                  </li>
                ))}
                <li>
                  <a href="https://www.facebook.com/taxiprawobrzeze">
                    Nasz profil na Facebook
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div data-g={"container"} className={"footer__bottom-content"}>
          <div className="footer__phone">
            <Phone />
            914 615 615
          </div>
          <div className="footer__hr"></div>
          <div className="footer__meta">
            Taxi Prawobrzeże <Confetti /> Szczecin {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  </>
)

export default Footer
