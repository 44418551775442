import { useEffect, useState } from "react"
import { useEffectOnce, useUpdateEffect } from "react-use"
import * as React from "react"

function Cookies() {
  const [visible, setVisibility] = useState(false)
  const [accepted, setState] = useState(false)

  useUpdateEffect(() => {
    localStorage.setItem("accept", accepted ? "1" : "0")
    setVisibility(false)
  }, [accepted])

  useEffectOnce(() => {
    // @ts-ignore
    setVisibility(localStorage.getItem("accept") !== "1")
    if (localStorage.getItem("accept") === "1") {
      setState(true)
    }
  })

  return (
    <div className={"cookies-wrapper"}>
      <div className={"cookies" + (visible ? " cookies--active" : "")}>
        <div className="cookies__heading">Ta strona używa plików cookies</div>
        <div className="cookies__description">
          W celu dostarczenia najwyżej jakości usług, na naszej stronie używamy
          plików Cookies. Dowiedz się więcej na temat{" "}
          <a href="/polityka-prywatnosci">polityki prywatności</a>.
        </div>
        <div className="cookies__links">
          <a
            className="button button--primary"
            onClick={() => {
              setState(true)
            }}
          >
            Akceptuj
          </a>
        </div>
      </div>
    </div>
  )
}

export default Cookies
