import * as React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "../../styles/style.scss"
import Footer from "./footer"
import HeaderMobile from "./header-mobile"
import Cookies from "./cookies"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <HeaderMobile />
      <main>{children}</main>
      <Footer />
      <Cookies />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
