import * as React from "react"
import { Link } from "gatsby"
import Phone from "../svg/phone"
import { useState } from "react"

const links = {
  "/oferta": "Oferta",
  "/cennik": "Cennik",
  "/dla-firm": "Dla firm",
}

const HeaderMobile = () => {
  const [menuState, setMenuState] = useState(false)

  return (
    <header className={"header-mobile"}>
      <div className="header-mobile__logo">
        <Link to="/">
          <img src="/images/logo.png" alt="logo" />
        </Link>
      </div>

      <div
        className={menuState ? "burger burger--active" : "burger"}
        onClick={() => setMenuState(!menuState)}
      >
        <div className="burger__line"></div>
        <div className="burger__line"></div>
      </div>

      <div
        className={
          menuState
            ? "header-mobile__content header-mobile__content--active"
            : "header-mobile__content"
        }
      >
        <div className="header-mobile__menu-title">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={330}
            height={18}
            viewBox="0 0 330 18"
            fill="none"
          >
            <line
              x1="0.5"
              y1="8.5"
              x2={119}
              y2="8.5"
              stroke="#E4E4E4"
              strokeLinecap="round"
            />
            <path
              d="M155.445 4.228V14H153.485V7.644L150.867 14H149.383L146.751 7.644V14H144.791V4.228H147.017L150.125 11.494L153.233 4.228H155.445ZM160.093 5.81V8.26H163.383V9.814H160.093V12.404H163.803V14H158.133V4.214H163.803V5.81H160.093ZM174.692 14H172.732L168.294 7.294V14H166.334V4.214H168.294L172.732 10.934V4.214H174.692V14ZM179.311 4.228V10.276C179.311 10.9387 179.484 11.4473 179.829 11.802C180.174 12.1473 180.66 12.32 181.285 12.32C181.92 12.32 182.41 12.1473 182.755 11.802C183.1 11.4473 183.273 10.9387 183.273 10.276V4.228H185.247V10.262C185.247 11.0927 185.065 11.7973 184.701 12.376C184.346 12.9453 183.866 13.3747 183.259 13.664C182.662 13.9533 181.994 14.098 181.257 14.098C180.529 14.098 179.866 13.9533 179.269 13.664C178.681 13.3747 178.214 12.9453 177.869 12.376C177.524 11.7973 177.351 11.0927 177.351 10.262V4.228H179.311Z"
              fill="#ACADB4"
            />
            <line
              x1={211}
              y1="8.5"
              x2="329.5"
              y2="8.5"
              stroke="#E4E4E4"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <ul className="header-mobile__menu">
          <li
            className="header-mobile__menu__item"
            onClick={() => setMenuState(!menuState)}
          >
            <Link to="/oferta">Oferta</Link>
          </li>
          <li
            className="header-mobile__menu__item"
            onClick={() => {
              setMenuState(!menuState)
            }}
          >
            <Link to="/cennik">Cennik</Link>
          </li>
          <li
            className="header-mobile__menu__item"
            onClick={() => {
              setMenuState(!menuState)
            }}
          >
            <Link to="/dla-firm">Dla firm</Link>
          </li>
          <li
            className="header-mobile__menu__item"
            onClick={() => {
              setMenuState(!menuState)
            }}
          >
            <a href="http://mobitaxi.szczecin.pl/">Aplikacja</a>
          </li>
          <li
            className="header-mobile__menu__item"
            onClick={() => {
              setMenuState(!menuState)
            }}
          >
            <Link to="/o-nas">O nas</Link>
          </li>
          <li
            className="header-mobile__menu__item"
            onClick={() => {
              setMenuState(!menuState)
            }}
          >
            <Link to="/dolacz">Dołącz i zarabiaj</Link>
          </li>
        </ul>
        <div className="header-mobile__call">
          <a className={"button button--primary"} href={"tel:914-615-615"}>
            <Phone />
            Zamów taxi
          </a>
        </div>
      </div>
    </header>
  )
}

export default HeaderMobile
